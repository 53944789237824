<template>
	<div>
		<!-- 代付记录 -->
		<div class="queryBox">
			<div class="mb20">
				<el-select v-model="state" placeholder="请选择订单状态" size="mini" class="mr20" clearable>
					<el-option key="" label="全部" value=""></el-option>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				<!-- <el-select v-model="payType" placeholder="请选择支付方式" size="mini" class="mr20" clearable>
					<el-option key="" label="全部" value=""></el-option>
					<el-option v-for="item in payTypeArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select> -->
				<el-input size="mini" placeholder="请输入商户订单号" v-model="order_id" clearable class="mr20" style="width: 200px"></el-input>
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"  :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">查询</el-button>
				<el-button size="mini" type="primary" :disabled="tableData.length==0" icon="el-icon-document" @click="getExcel">导出Excel</el-button>
			</div>
		</div>
		<template>
			<el-table size="mini" :data="tableData" border="" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" align="center" width="45px">
					<template slot-scope="scope">
						<span v-if="scope.row.id">{{scope.$index+1}}</span>
						<span v-if="scope.$index==tableData.length-2">单页合计</span>
						<span v-if="scope.$index==tableData.length-1">总计</span>
					</template>
				</el-table-column>
				<el-table-column prop="order_id" label="商家订单号" align="center">
					<template slot-scope="scope">
						{{scope.row.order_id}}
					</template>
				</el-table-column>
				<el-table-column prop="water_no" label="平台交易号" align="center">
					<template slot-scope="scope">
						{{scope.row.water_no}}
					</template>
				</el-table-column>
				<el-table-column label="交易金额" align="center">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							{{scope.row.gold | tofixed}}
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							{{scope.row.gold | tofixed}}
						</div>
						<p v-else>{{scope.row.gold | tofixed}}</p>
					</template>
				</el-table-column>	
				<el-table-column label="手续费率(‰)" align="center">
					<template slot-scope="scope">
						<p >{{scope.row.fee | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column label="手续费" align="center">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							{{scope.row.fee_gold_tra}}
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							{{scope.row.fee_gold_tra}}
						</div>
						<p v-else>{{scope.row.fee_gold_tra | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column label="单笔手续费" align="center">
					<template slot-scope="scope">
						<!-- <span>{{scope.row.fee_gold_num | tofixed}}</span> -->

						<div v-if="scope.$index == tableData.length-2">
							{{scope.row.fee_gold_num}}
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							{{scope.row.fee_gold_num}}
						</div>
						<p v-else>{{scope.row.fee_gold_num | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column label="实际扣除" align="center">
					<template slot-scope="scope">
						<div v-if="scope.$index == tableData.length-2">
							{{scope.row.trader_gold | tofixed}}
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							{{scope.row.trader_gold | tofixed}}
						</div>
						<p v-else>{{scope.row.trader_gold | tofixed}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="" label="代付信息" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.type === 9 || scope.row.cur_type==='usdt'|| scope.row.cur_type==='USDT'">
							<p v-if="scope.row.type === 9">
								{{scope.row.usdt_gold}} <sub>usdt</sub>	
							</p>
							<el-popover
								placement="top"
								trigger="click">
								<div>
									{{scope.row.usdt_address}}
								</div>
								<p slot="reference" class="clamp_2">{{scope.row.usdt_address}}</p>
							</el-popover>
						</div>
						<div v-else>
							{{scope.row.bank}}
							<br>
							{{scope.row.name}}
							<br>
							{{scope.row.card}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="创建时间" align="center">
					<template slot-scope="scope">
						{{scope.row.add_time}}
					</template>
				</el-table-column>
				<el-table-column prop="action_time" label="成功时间" align="center">
					<template slot-scope="scope">
						{{scope.row.action_time}}
					</template>
				</el-table-column>
				<el-table-column prop="state_name" label="交易状态" align="center">
					<template slot-scope="scope">
						<div :class="scope.row.state==1?'csuccess':'cdanger'">
							{{scope.row.state_name}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="" label="查看回单" width="150px" align="center">
					<template slot-scope="scope">
						<el-button v-if="scope.row.state == 1 && scope.row.pay_type == 88" size="mini" @click="checkOrderImg(scope.row)">查看回单</el-button>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="" label="操作" width="300px" align="center"> -->
					<!-- <template slot-scope="scope"> -->
						<!-- <el-button size="mini">确认收款</el-button> -->
						<!-- <el-button size="mini">复核确认</el-button> -->
						<!-- <el-button size="mini">复核取消</el-button> -->
					<!-- </template> -->
				<!-- </el-table-column> -->
				
				
			
			</el-table>
		</template>

		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
		
		<!-- 回单图片展示 -->
		<el-dialog
		  title="回单"
		  :visible.sync="imgDialogVisible"
		  width="60%"
		  :before-close="imgHandleClose">
		  
		  <el-card class="box-card" style="background-color: bisque;">
			  
		    <div v-for="(v, i) in imgs" :key="i" class="text item imgcon">
				
				
				<img v-if="v.receipt_url" :src="v.receipt_url" class="imgchild">
				
				<img v-if="v.water_url" :src="v.water_url" class="imgchild">
				
		    </div>
		  </el-card>
		  
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="imgHandleClose()">确 定</el-button>
		  </span>
		</el-dialog>


	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],	
				date: null,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				state: '',
				stateArr: {},
				payType: '',
				payTypeArr: [],
				order_id: '',
				// 查看回单
				imgs: [],
				imgDialogVisible: false,
				
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.stateList()
			// this.payTypeList()
			this.orderOutList()

		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			imgHandleClose(){
				this.imgs = []
				this.imgDialogVisible = false
			},
			checkOrderImg(item){
				let data = {
					order_id: item.order_id,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.checkRepayImg(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.imgs = res.data
						this.imgDialogVisible = true
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
				
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			getExcel() {
				let username = local.get('shusername');
				let tid = local.get('shtid')
				let start_time=this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '';
				let end_time=this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '';
				console.log(username,tid)
				let href = `${this.$baseURL}/trader/order/exportOutList?username=${username}&tid=${tid}&start_time=${start_time}&end_time=${end_time}&type='1,2,9'`
				window.location.href = href
			},
			stateList() {
				this.$api.outStateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			// payTypeList() {
			// 	this.$api.payType({}).then(res => {
			// 		if (res.status === 1) {
			// 			this.payTypeArr = res.data.data
						
			// 		} else {
			// 			this.$message({
			// 				type: 'error',
			// 				message: res.msg
			// 			})
			// 		}
			// 	})
			// },
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderOutList();
			},
		
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.orderOutList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderOutList()
			},
			orderOutList() {
				let data = {
					order_id: this.order_id,
					// pay_type: this.payType,
					state: this.state,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					page: this.page,
					size: this.pageSize,
					type: '1,2',
				}
				// this.loading = true
				this.disLoading = true
				this.$api.orderOutList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data.filter(item=>{
							// if(item.order_id){
								return item
							// }
						});
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			
		}
	};
</script>

<style>
	.imgcon{
		display: flex;
		justify-items: center;
		margin-bottom: 15px;
		justify-content: space-around;
	}
	
	.imgchild{
		width: 45%;
	}
</style>
