<template>
	<div class="flexX flexAround">
		<!-- <div class="mb20 pageTitle"><b>提现</b></div> -->
		<div v-loading="loading" >
			<div class="ml10 fs20 mb10">单笔代付</div>
			<el-form size="mini" :label-position="labelPosition" ref="ruleForm" :model="form" :rules="rules" :label-width="labelWidth" class="form">
				<el-form-item label="商户号:">
					{{userInfo.business_no}}
				</el-form-item>
				<el-form-item label="可用余额:">
					{{userInfo.money | tofixed}}
				</el-form-item>
				<template>
					<!-- 新增随机订单号输入框 -->
					<el-form-item label="订单号:" prop="orderNumber">
					  <el-input style="width: 250px;" class="mr20" placeholder="自动生成订单号" v-model="form.orderNumber" clearable></el-input>
					  <el-button @click="generateOrderNumber">随机订单号</el-button>
					</el-form-item>
					<el-form-item label="收款户名:"  prop="name">
						<el-input style="width: 250px;"  class="mr20" placeholder="请输入收款户名" v-model="form.name" clearable></el-input>
					</el-form-item>
					<el-form-item label="收款账号:" prop="card">
						<el-input style="width: 250px;"  class="mr20" placeholder="请输入收款账号" v-model="form.card" clearable></el-input>
					</el-form-item>
					<el-form-item label="开户行:" prop="bank">
						<el-input style="width: 250px;"  class="mr20" placeholder="请输入开户行" v-model="form.bank" clearable></el-input>
					</el-form-item>
					<div style="width:370px; paddingLeft:40px" class="mb10">
						<el-collapse style="textAlign:center" v-model="activeNames" @change="handleChange">
							<el-collapse-item title="其他要素" name="1">
								<div class="form-div"><span class="form-label">身份证号:</span><el-input size="mini" style="width: 250px;"  class="mr20" placeholder="请输入身份证号" v-model="form.certId" clearable></el-input></div>
								<div class="form-div mt15"><span class="form-label">手机号:</span><el-input size="mini" style="width: 250px;"  class="mr20" placeholder="请输入手机号" v-model="form.mobile" clearable></el-input></div>
							
							</el-collapse-item>
						</el-collapse>
					</div>
					<el-form-item label="代付编号:" prop="pay_type">
						  <el-select v-model="form.pay_type" placeholder="请选择">
						    <el-option
						      v-for="item in repays"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					</el-form-item>
				</template>
				<el-form-item label="金额(元):" prop="money">
					<el-input-number style="width: 250px;" controls-position="right"  class="mr20" placeholder="请输入付款金额" v-model="form.money" clearable></el-input-number>
					<!-- <span class="cdanger">(温馨提示：为避免资金退回，请以商户最高额度提现。单笔最高{{maxmoney}}元)</span> -->
				</el-form-item>
				<el-form-item label="代付密码:" prop="payPwd">
						<el-input type="password" style="width: 250px;" controls-position="right"  class="mr20" placeholder="请输入代付密码" v-model="form.payPwd" clearable></el-input>
				</el-form-item>
				<el-form-item label="谷歌验证码:" prop="code">
						<el-input style="width: 250px;" controls-position="right"  class="mr20" placeholder="请输入谷歌验证码" v-model="form.code" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button v-loading="submitLoading" type="primary" class="mr20" @click="sure">提交申请</el-button>
					<span class="cinfo">( 注：实际到账时间请以银行时间为准 )</span>
				</el-form-item>

			</el-form>

		</div>
		<!-- <div style="border-right: 0.0625rem groove black;">
			
		</div> -->
		<!-- <el-divider direction="vertical" class="mt60 mb20" content-position="center">提现 多笔文件上传</el-divider> -->
		
		<div v-loading="loading">
			<div class="ml10 fs20 mb10">批量代付</div>
			<el-form size="mini" :label-position="labelPosition" ref="ruleForm" :model="form" :rules="rules" :label-width="labelWidth" class="form">
				<el-form-item label="文件上传:" prop="paymentOrderFile">
						
					<el-link class="mb10" type="primary" @click="uploadFile">下载模板</el-link>
					<el-upload
					  style="width:200px"
					  class="upload-demo"
					  action="none"
					  :auto-upload="false"
					  :limit="1"
					  :on-change="handleChange"
					  :on-remove="handleRemove"
					  :on-exceed="handleExceed"
					  :file-list="fileList">
					  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
					  <div slot="tip" class="el-upload__tip">只能上传XLS文件，且不超过500kb</div>
					</el-upload>
				</el-form-item>
					<el-form-item label="代付编号:" prop="rpType">
						  <el-select v-model="rpType" placeholder="请选择">
						    <el-option
						      v-for="item in repays"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					</el-form-item>
				<el-form-item label="代付密码:" prop="payPwd">
						<el-input type="password" style="width: 250px;" controls-position="right"  class="mr20" placeholder="请输入代付密码" v-model="payPwd" clearable></el-input>
				</el-form-item>
				<el-form-item label="谷歌验证码:" prop="upload_code">
						<el-input style="width: 250px;" controls-position="right"  class="mr20" placeholder="请输入谷歌验证码" v-model="upload_code" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button v-loading="submitLoading" type="primary" class="mr20" @click="uploadSure">上传代付</el-button>
					<span class="cinfo">( 注：实际到账时间请以银行时间为准 )</span>
				</el-form-item>
		
			</el-form>
		
		</div>
	</div>
</template>

<script>
	import local from '../../util/local.js'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				activeNames:'',
				form:{
					orderNumber:'',
					name:'',
					card:'',
					bank:'',
					money:'',
					code:'',
					payPwd:'',
					pay_type:'',
					certId:'',
					mobile:'',
				},
				rules: {
					cur_id: [
						{ required: true, message: '请输入币种', trigger: 'blur' },
					],
					name: [
						{ required: true, message: '请输入持卡人姓名', trigger: 'blur' },
					],
					card: [
						{ required: true, message: '请输入卡号', trigger: 'blur' },
					],
					bank: [
						{ required: true, message: '请输入银行名称', trigger: 'blur' },
					],
					money: [
						{ required: true, message: '请输入金额', trigger: 'blur' },
					],
					code: [
						{ required: true, message: '请输入谷歌验证码', trigger: 'blur' },
					],
					payPwd: [
						{ required: true, message: '请输入代付密码', trigger: 'blur' },
					],
				},
				loading:false,
				labelPosition: 'right',
				userInfo: {},
				username: local.get('shusername'),
				labelWidth: '120px',
				innerVisible: false,
				innerloading: false,
				dialogloading: false,
				qrcode: '',
				name:'',
				card:'',
				bank:'',
				money:'',
				code:'',
				payPwd:'',
				payPwdKey:'',
				payway:'',
				paywayArr:[],
				maxmoney:49999,
				currencyListMoney:[],
				// 文件上传
				fileList: [],
				upload_code: '',
				// 代付类型
				repays: [],
				rpType: '',
				submitLoading:false
			}
		},
		created() {
			this.getInfo()
			this.getRepayTypes()
			this.generateOrderNumber()
			// this.paywayList()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			generateOrderNumber() {
			  // 基于时间戳和随机数生成唯一订单号
				const timestamp = Date.now(); // 当前时间戳
				const randomNum = Math.floor(Math.random() * 10000); // 生成6位随机数
				this.form.orderNumber = `Tra${timestamp}${randomNum}`;
			},
			getRepayTypes(){
				this.$api.repayType({}).then(res=>{
					if (res.status == 1) {
						this.repays = res.data.data
					}else{
						this.$message({type:"error",message:res.msg});
					}
				})
			},
			  uploadFile(){
				  	let username = local.get('shusername');
				  	let tid = local.get('shtid')
				  	let href =
				  		`${this.$baseURL}/trader/order/downLoadFile?username=${username}&tid=${tid}`
				  	window.location.href = href
			  },
			  handleRemove(file, fileList){
				  // console.log('Remove')
				  // console.log(file)
				  // console.log(fileList)
				  this.fileList[0] = ''
				  // console.log(this.fileList)
			  },
			  handleChange(file, fileList){
				  // console.log('change')
				  // console.log(file)
				  // console.log(fileList)
				  this.fileList[0] = file
				  // console.log(this.fileList)
			  },
			uploadSure(item, index) {
				this.$confirm('确认上传代付吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							
							if(this.fileList[0] == undefined || isEmpty(this.fileList[0])){
								this.$message({type:"error",message:"请上传文件"});
								return;
							}
							if(isEmpty(this.upload_code)){
								this.$message({type:"error",message:"请输入谷歌验证码"});
								return;
							}
							if(isEmpty(this.rpType)){
								this.$message({type:"error",message:"请选择代付编号"});
								return;
							}
							if(isEmpty(this.payPwd)){
								this.$message({type:"error",message:"请输入代付密码"});
								return;
							}
							this.submitLoading = true
							let formData = new FormData();
							formData.append('username', local.get('shusername'))
							formData.append('tid', local.get('shtid'))
							formData.append('code', this.upload_code)
							formData.append('pay_type', this.rpType)
							formData.append('payPwd', this.$md5(this.payPwd))
							formData.append('paymentOrderFile', this.fileList[0].raw)
							// 提交到后台.
							this.$api.createXlsPaymentOrder(formData).then(res => {
								// console.log(res);
								if (res.status === 1) {
									
									if(res.data.is_success === 2){
										let username = local.get('shusername');
										let tid = local.get('shtid')
										let href = `${this.$baseURL}/trader/order/downLoadErrorFile?username=${username}&tid=${tid}&path=${res.data.path}`
										window.location.href = href
										
										this.$message({
											type:'error',
											message:res.data.err_msg,
											duration:0,
											showClose:true,
											})
									}else{
										this.$refs['ruleForm'].resetFields();
										this.$message({type:'success',message:res.msg})
										this.getInfo()
									}
									
									this.$router.push({path:'/paymentRecord'})
									
	
									
								} else {
									this.$message({
										type: 'error',
										message: res.msg
									})
								}
							}).catch(error => {
								this.fileList = []
								this.submitLoading = false
								
							})
							
					}).catch(() => {
					  this.$message({
						type: 'info',
						message: '已取消确认转账'
					  });          
					});
					
			},
		    handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		    },
			//查询基本信息
			getInfo() {
				// this.dialogloading = true
				this.$api.userInfo({}).then(res => {
					// this.dialogloading = false;
					if (res.status == 1) {
						this.userInfo = res.data
					}
				}).catch(error => {
					// this.dialogloading = false
				})
			},
		
			//通道列表
			paywayList() {
				// this.dialogloading = true
				this.$api.paywayList({}).then(res => {
					if (res.status == 1) {
						this.paywayArr = res.data
					}
				}).catch(error => {
				})
			},
		
			sure() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						
						this.loading = true;
						// this.submitLoading = true
						let pwd = this.$md5(this.form.payPwd)
						// this.form.payPwd = this.$md5(this.payPwdKey)
						let form = {...this.form}
						form.payPwd = pwd
						let obj = JSON.parse(JSON.stringify(form))
						// let obj = JSON.parse(JSON.stringify(this.form))
						
						this.$api.userMentionMoney(obj).then(res=>{
							this.loading = false;
							if(res.status == 1){
								this.$refs['ruleForm'].resetFields();
								this.$router.push({path:'/paymentRecord'})
								this.$message({type:'success',message:res.msg})
								this.getInfo()
								this.submitLoading = false
							}else{
								this.$message({type:'error',message:res.msg})
								this.submitLoading = false
							}
						}).catch(error=>{
							this.loading = false;
							this.submitLoading = false
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				
					
				// let data = {
				// 	name:this.name,
				// 	card:this.card,
				// 	bank:this.bank,
				// 	money:this.money,
				// 	code:this.code,
				// 	payway_type_id:this.payway,
				// }
				// this.loading = true;
				// this.$api.mentionMoney(data).then(res=>{
				// 	this.loading = false;
				// 	if(res.status == 1){
				// 		this.name = '';
				// 		this.card = '';
				// 		this.bank = '';
				// 		this.money = '';
				// 		this.code = '';
				// 		this.payway = '';
				// 		this.$router.push({path:'/tirunOrder'})
				// 		this.$message({type:'success',message:res.msg})
				// 		this.getInfo()
				// 	}else{
				// 		this.$message({type:'error',message:res.msg})
				// 	}
				// }).catch(error=>{
				// 	this.loading = false;
				// })
			},
		}
	}
</script>


<style  lang="scss" scope>
	.el-collapse-item__header{
		justify-content: center;
    	position: relative;
		height:32px;
	}
	.el-collapse-item__arrow{
		margin-left:10px;
		position: absolute;
		right: 10px;
		
	}
	.form-div{
		display:flex;
		.form-label{
			width: 80px;
			font-size: 0.7291666666667vw;
			font-weight: 600;
			color: #606266;
			margin-right: 10px;
			padding-right:2px;
			text-align:right;
		}
	}

</style>
<style lang="scss">
	@import '@/style/variables.scss';

	.pageTitle {
		background-color: $tableBorderColor;
		padding: 10px;
	}

	.form {
		// width: 500px;
		input{
			// width: 200px;
		}
	}

	.qrcode {
		// display: inline-block;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			max-width: 100%;
			background-color: #fff; //设置白色背景色
			box-sizing: border-box;
		}
	}
</style>
